<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Commenting on items</h3>

	<p>Any signed-in user can add <b>comments</b> to any item in a framework (you can also add comments to a framework document):</p>
	<ul>
		<li>First <span v-html="link('signing_in', 'sign in')"></span> to <span v-html="app_name"/>, if you haven’t already.</li>
		<li><img srcset="/docimages/add_comments-1.png 3x" class="k-help-img float-right">Then turn on “comment mode” by clicking the <v-icon small>fas fa-comment</v-icon> icon in the upper-right corner of the window. (If the icon has a white circle around it, comment mode is already on.)</li>
		<li><img srcset="/docimages/add_comments-2.png 3x" class="k-help-img float-right">With comment mode on, when you click on any framework item from the <span v-html="link('treeview', 'tree view')"></span> to view the item <span v-html="link('item_card', 'card')"></span>, you will see a <nobr><v-icon small>fas fa-plus</v-icon> COMMENT</nobr> button on the right side of the card. Click this button to open the comment editor:</li>
	</ul>
	<p><img srcset="/docimages/add_comments-3.png 2x" class="k-help-img"></p>
	<ul>
		<li>Type in your comment, then click <nobr><v-icon small>fas fa-check</v-icon> SAVE COMMENT</nobr> when you’re done.</li>
		<li>By default, your comment will be a “Personal Comment”, <i>viewable only to you</i>. You can also set up, or be added to, a <span v-html="link('comment_groups', 'comment group')"></span> to share comments with others.</li>
		<li>To be clear: <b>no comment is ever viewable by every user of <span v-html="app_name"/>.</b> Personal comments are viewable only to the person who wrote the comment, and comments posted to a comment group are only viewable by members of that group.</li>
		<li>Click the <nobr><v-icon small>fas fa-comment</v-icon> SHOW ALL</nobr> button from the item card to <span v-html="link('show_all_comments', 'show all comments')"></span> for the framework in a searchable table.</li>
		<li>You can also <span v-html="link('suggest_edits', 'suggest edits')"></span> to a standard in a comment. The help document on <span v-html="link('reviewing', 'reviewing frameworks')"></span> explains the workflow for reviewing and editing frameworks using comments and comment groups.</li>
	</ul>
	<p>Once you’ve saved your comment, it will appear on the item’s card (<i>if you have comment mode turned on, as described above</i>):</p>
	<img srcset="/docimages/add_comments-5.png 2x" class="k-help-img block">
	<ul>
		<li><img srcset="/docimages/add_comments-6.png 2x" class="k-help-img float-right">Hover your mouse over any comment to reveal an “Edit” button (for comments you’ve written) and a “Reply” button (for comments written by you or other members of your comment group(s)).</li>
		<li>Replies to a comment posted to a comment group will also be viewable by everyone in the comment group.</li>
		<li><img srcset="/docimages/add_comments-4.png 2x" class="k-help-img float-right">When editing a comment, you have the option to archive the comment. Archived comments are no longer seen by either you or members of your comment groups. If you archive a comment that has replies, the replies will also be archived.</li>
	</ul>
	
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	